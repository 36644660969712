<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="header">
      <div class="header_box" v-if="productData">
        <div class="header_img">
          <img :src="productData.fileList[showImg].url" alt="" />

          <div class="header_img_box">
            <img
              :src="item.url"
              alt=""
              v-for="(item, index) in productData.fileList"
              :key="index"
              @click="changeImg(index)"
              :class="showImg == index ? 'img' : ''"
            />
          </div>
          <div class="header_img_arrows">
            <img src="@/assets/index/left.png" alt="" @click="left" />
            <img src="@/assets/index/rigth.png" alt="" @click="right" />
          </div>
        </div>
        <div class="header_main">
          <div class="header_main_title">{{ productData.name }}</div>
          <div class="header_main_msg">
            <div v-html="productData.description"></div>
          </div>
          <div class="header_main_try" @click="openPopUp">APPLY FOR DEMO</div>
        </div>
      </div>
    </div>
    <!-- 尝试模块 -->
    <div class="try" :class="{ active: isActive }">
      <div class="try_checkBox">
        <div
          class="try_top"
          :class="checkedTitle == 1 ? 'try_top_checked' : ''"
        >
          <a href="#try" @click="changeTitle(1)">DESCRIPTION</a>
        </div>
        <div class="try_top" @click="changeTitle(4)">
          <a href="#downloads" @click="changeTitle(4)">DOWNLOADS</a>
        </div>
        <div
          class="try_top"
          :class="checkedTitle == 2 ? 'try_top_checked' : ''"
        >
          <a href="#sheets " @click="changeTitle(2)">SERVICES</a>
        </div>
        <div
          class="try_top"
          :class="checkedTitle == 3 ? 'try_top_checked' : ''"
        >
          <a href="#models" @click="changeTitle(3)">MODELS & ACCESSORIES</a>
        </div>
      </div>
    </div>
    <!-- try_content -->
    <div v-if="productOverviews">
      <div
        class="try_content"
        :id="index == 0 ? 'try' : ''"
        v-for="(item, index) in productOverviews"
        :key="index"
        :style="{ margin: item.url == 0 ? '20px auto 0' : '' }"
      >
        <img
          v-show="index % 2 == 0"
          :src="item.url"
          alt=""
          :class="item.url ? '' : 'img1'"
        />
        <div>
          <div
            v-html="item.description"
            :class="item.url ? 'div2' : 'div1'"
          ></div>
        </div>
        <img
          v-show="index % 2 == 1"
          :src="item.url"
          alt=""
          :class="item.url ? '' : 'img1'"
        />
      </div>
    </div>

    <div class="try_try" id="downloads" @click="openPopUp">APPLY FOR DEMO</div>
    <!-- data sheets -->
    <div class="sheets" id="sheets" v-if="dataSheetsList.length > 0">
      <div
        class="sheets_box"
        v-for="(item, index) in dataSheetsList"
        :key="index"
      >
        <div class="sheets_box_title">
          <div class="sheets_box_title_box">
            <span>{{ item.documentType }}</span>
            <img
              src="@/assets/index/add.png"
              alt=""
              v-show="showDataSheetsList != index + 1"
              @click="changeShow(index + 1)"
            />
            <img
              src="@/assets/index/subtract.png"
              alt=""
              v-show="showDataSheetsList == index + 1"
              @click="changeShow(0)"
            />
          </div>
        </div>
        <div
          class="sheets_box_main"
          v-for="(item2, index2) in item.seriesList"
          :key="index2"
          :class="showDataSheetsList == index + 1 ? 'show' : ''"
        >
          <div class="sheets_box_main_title">
            {{ item2.seriesName }}
          </div>
          <div
            class="sheets_box_main_list"
            v-for="(item3, index3) in item2.fileList"
            :key="index3"
          >
            <div>
              <img
                src="@/assets/index/download.png"
                alt=""
                @click="downloadFile(item3.fileUrl, item3.fileName)"
              />{{ item3.fileName }}
            </div>
            <div>
              <img
                src="@/assets/index/pdf.png"
                alt=""
                @click="downloadOpen(item3.fileUrl)"
              /><span>pdf</span>{{ (item3.fileSize / 1024).toFixed(2) }}M
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- models 模型 -->
    <div class="models" id="models" v-if="modelsList.length > 0">
      <div class="models_title">MODELS & ACCESSORIES</div>
      <div class="models_box" v-for="(item, index) in modelsList" :key="index">
        <div class="models_box_title">
          <div class="models_box_title_box">
            <span>{{ item.name }}</span>
            <img
              src="@/assets/index/add.png"
              alt=""
              v-show="showModelsList != index + 1"
              @click="changeShow2(index + 1)"
            />
            <img
              src="@/assets/index/subtract.png"
              alt=""
              v-show="showModelsList == index + 1"
              @click="changeShow2(0)"
            />
          </div>
        </div>
        <div
          class="models_box_main"
          :class="showModelsList == index + 1 ? 'show' : ''"
        >
          <div
            class="models_box_main_list"
            v-for="(item2, index2) in item.explanationList"
            :key="index2"
          >
            <!-- @click="PinkImg(item2)" -->
            <img :src="item2.url" alt="" />
            <div>
              <div>
                {{ item2.content }}
              </div>
              <div>{{ item2.apply }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <div class="popUp" @click="closePopUp" v-show="showPopUp">
      <div class="popUp_main">
        <img
          src="@/assets/index/close.png"
          alt=""
          @click.stop="showPopUp = false"
        />
        <div class="popUp_main_title">
          {{ productData ? productData.name : "" }} - APPLY FOR DEMO
        </div>
        <div class="popUp_main_form">
          <div>
            <div>First Name *</div>
            <input type="text" v-model="formData.firstName" />
          </div>
          <div>
            <div>Last Name *</div>
            <input type="text" v-model="formData.lastName" />
          </div>
          <div>
            <div>Company *</div>
            <input type="text" v-model="formData.company" />
          </div>
          <div>
            <div>eMail *</div>
            <input type="text" v-model="formData.email" />
          </div>
          <div>
            <div>Phone</div>
            <input type="text" v-model="formData.phone" />
          </div>
          <div>
            <div>Country *</div>
            <!-- <input type="text" v-model="formData.country" /> -->
            <el-select
              v-model="formData.country"
              no-match-text="No data"
              filterable
              placeholder=""
            >
              <el-option
                v-for="item in countryList"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="popUp_main_submit" @click="submit">SUBMIT</div>
      </div>
    </div>
    <div class="popUp2" @click="closePinkImg" v-show="showPinkImg">
      <div>
        <img :src="pinkImgMsg.url" alt="" />
        <div>
          <div>
            {{ pinkImgMsg.content }}
          </div>
          <div>{{ pinkImgMsg.apply }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
import { Loading } from "element-ui";
import countryList from "@/utils/country";
// import downloadjs from "downloadjs";
export default {
  created() {
    this.countryList = countryList;
  },
  mounted() {
    //面包屑导航
    if (this.$route.query.id) {
      let id = this.$route.query.id;
      this.productInfo(id);
      this.productDownloadList(id);
      this.productMouldList(id);
      this.productOverviewsList(id);
      sessionStorage.setItem("productId", id);
    } else {
      this.productInfo(sessionStorage.getItem("productId"));
      this.productDownloadList(sessionStorage.getItem("productId"));
      this.productMouldList(sessionStorage.getItem("productId"));
      this.productOverviewsList(sessionStorage.getItem("productId"));
    }
    this.$nextTick(() => {
      window.addEventListener("scroll", this.tryTop);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.tryTop);
  },
  watch: {
    $route(to) {
      if (to.query.id) {
        let id = to.query.id;
        this.productInfo(id);
        this.productDownloadList(id);
        this.productMouldList(id);
        this.productOverviewsList(id);
        sessionStorage.setItem("productId", id);
      }
    },
  },
  data() {
    return {
      breadcrumbList: [],
      productData: {}, //产品信息
      activeName: "first", //尝试模块的当前页面
      dataSheetsList: [], //data sheets数据（下载列表）
      showDataSheetsList: -1, //当前打开的data sheets
      modelsList: [], //models 数据
      showModelsList: -1, //当前打开的MODELS & ACCESSORIES
      showPopUp: false, //是否显示弹窗
      showPinkImg: false, //是否显示大图片
      pinkImgMsg: {},
      formData: {
        company: "",
        country: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        productId: "",
      }, //提交的表单信息（弹窗）
      submitFlag: true,
      showImg: 0, //当前显示的图片
      imgScroll: 0,
      countryList: [],
      productOverviews: [{ description: "", url: "" }], //产品概述
      isActive: false,
      checkedTitle: 1, //选择的标签
    };
  },
  components: {
    Breadcrumb,
  },
  methods: {
    //图片左滑
    left() {
      let box = document.getElementsByClassName("header_img_box")[0];
      if (0 > this.imgScroll - 648) {
        this.imgScroll = 0;
      } else {
        this.imgScroll -= 648;
      }
      box.scroll(this.imgScroll, 0);
    },
    right() {
      let box = document.getElementsByClassName("header_img_box")[0];
      let width = 120 * this.productData.fileList.length - 20;

      if (width > this.imgScroll + 648) {
        this.imgScroll += 648;
      } else {
        this.imgScroll = width;
      }
      box.scroll(this.imgScroll, 0);
    },

    //切换显示的折叠筐
    changeShow(num) {
      this.showDataSheetsList = num;
    },
    //切换显示的折叠筐（models）
    changeShow2(num) {
      this.showModelsList = num;
    },
    //关闭弹窗
    closePopUp(e) {
      if (e.target.className == "popUp") {
        this.showPopUp = false;
      }
    },
    //打开弹窗
    openPopUp() {
      this.showPopUp = true;
    },
    //提交表单
    submit() {
      //请求接口
      let loadingInstance = Loading.service(document.body);
      let that = this;
      this.formData.productId = this.productData.id;
      if (!this.submitFlag) {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        return false;
      } else {
        this.submitFlag = false;
      }
      api.saveTrialApplication(that.formData).then((res) => {
        if (res.code == 200) {
          that.$message.success("申请成功！");
          that.formData = {
            company: "",
            country: "",
            email: "",
            firstName: "",
            lastName: "",
            phone: "",
            productId: "",
          };
          setTimeout(() => {
            that.submitFlag = true;
          }, 2000);
          that.$nextTick(() => {
            loadingInstance.close();
          });
          that.showPopUp = false;
        } else {
          that.$nextTick(() => {
            loadingInstance.close();
          });
          that.submitFlag = true;
          that.$message.error(res.msg);
        }
      });
    },
    //获取详情信息
    productInfo(id) {
      api.productInfo({ id }).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.productData = data.data;
          let descriptionList = this.productData.description.split("\n");
          this.productData.description = "";
          descriptionList.forEach((item, index) => {
            this.productData.description += item;
            if (index != descriptionList.length - 1) {
              this.productData.description += "<br/>";
            }
          });
          this.breadcrumbList = [
            { path: "/", title: "HOME" },
            { path: "/retalt", title: "PRODUCTS" },
            {
              path: "/retalt",
              title: sessionStorage.getItem("proTypeListName"),
            },
            { path: "", title: this.productData.name },
          ];
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    //切换显示的图片
    changeImg(index) {
      this.showImg = index;
    },
    //下载列表
    productDownloadList(id) {
      api.productDownloadList({ id }).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.dataSheetsList = data.data;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    //模具与配件
    productMouldList(id) {
      api.productMouldList({ id }).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.modelsList = data.data;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    //下载
    downloadFile(url, name) {
      //下载接口
      let url2 = window.URL.createObjectURL(new Blob([url]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url2;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      // console.log(url);
    },

    //预览
    downloadOpen(url) {
      window.open(url);
    },

    //Country的下拉搜索框
    querySearch(queryString, cb) {
      var restaurants = this.countryList;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    //获取产品概述
    productOverviewsList(id) {
      api.productOverviewsList({ id }).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.productOverviews = data.data;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    //切换标题
    changeTitle(num) {
      this.checkedTitle = num;
    },
    //查看大图
    PinkImg(item) {
      // console.log(item);
      this.showPinkImg = true;
      this.pinkImgMsg = item;
    },
    //关闭大图弹窗
    closePinkImg() {
      this.showPinkImg = false;
    },
    //try模块视口高度
    tryTop() {
      document.documentElement.scrollTop >= 984
        ? (this.isActive = true)
        : (this.isActive = false);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #f6f6f6;
  height: 950px;
}
.header_box {
  display: flex;
  justify-content: space-between;
  width: 1256px;
  margin: 0 auto;
  .header_img {
    width: 648px;
    padding: 79px 0 0;
    position: relative;

    height: 633px;
    overflow: hidden;
    // outline: 1px solid red;
    > img {
      width: 500px;
      height: 500px;
      margin-left: 74px;
      margin-bottom: 30px;
    }
    .header_img_box {
      display: flex;
      position: relative;
      z-index: 10;
      height: 124px;
      overflow: auto;
      width: 500px;
      margin: 0 auto;

      img {
        width: 100px;
        height: 100px;
        cursor: pointer;
        float: left;
      }
      .img {
        border: 1px solid #004a9c;
        height: 98px;
        width: 98px;
      }
      img:not(:nth-of-type(1)) {
        margin-left: 33px;
      }
    }

    .header_img_arrows {
      position: absolute;
      width: 100%;
      top: 615px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;

      img {
        position: relative;
        z-index: 11;
        cursor: pointer;
        width: 24px;
        height: 46px;

        padding: 29px 10px;
      }
      img:hover {
        background: #f1f1f1;
      }
    }
  }
  .header_main {
    width: 560px;
    text-align: center;
    .header_main_title {
      padding-top: 200px;
      font-size: 72px;
      font-family: Arial;
      font-weight: bold;
      color: #0d53a1;
    }
    .header_main_msg {
      padding-top: 16px;
      font-size: 16px;
      font-family: Arial;
      font-weight: 400;
      color: #5a5b5e;
      text-align: left !important;
    }
    .header_main_try {
      width: 310px;
      height: 60px;
      background: #0d53a1;
      margin: 100px auto;
      cursor: pointer;
      font-size: 18px;
      font-family: Arial;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      line-height: 60px;
    }
  }
}
.try {
  width: 100%;
  height: 46px;
  padding-top: 54px;
  position: absolute;
  top: 984px;
  z-index: 99;
  background: #f6f6f6;

  border-bottom: 1px solid #939393;
  .try_checkBox {
    width: 1156px;
    margin: 0 auto;
    display: flex;
    align-content: center;
    a {
      color: #5a5b5e;
    }
    .try_top {
      cursor: pointer;
      font-size: 18px;
      font-family: Arial;
      font-weight: bold;
      color: #5a5b5e;
      height: 47px;
      line-height: 47px;
      margin-right: 79px;
    }
    .try_top_checked {
      transition: 0.5s;
      border-bottom: 4px solid #0d53a1;
      height: 43px;
    }
  }
}
.active {
  //try
  position: fixed;
  top: 0;
  z-index: 101;
}
#try {
  margin-top: 30px;
}
.try_content {
  height: 741;
  width: 1156px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // padding-top: 99px;

  img {
    width: 320px;
  }
  .img1 {
    display: none;
  }
  > div {
    display: table;
    div {
      width: 790px;
      font-size: 16px;
      line-height: 24px;
      font-family: Arial;
      font-weight: 400;
      color: #5a5b5e;
    }
    .div1 {
      width: 1100px;
      margin: 0 auto;
    }
    .div2 {
      display: table-cell;
      vertical-align: middle;
      left: 0;
    }
  }
}
.try_content:not(:nth-of-type(1)) {
  // padding-top: 48px;
}
.try_try {
  width: 310px;
  height: 60px;
  cursor: pointer;
  background: #0d53a1;
  margin: 100px auto;

  font-size: 18px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
}
.sheets {
  .sheets_box {
    border-bottom: 1px dashed #ccc;
    .sheets_box_title {
      height: 80px;
      background: #f1f2f7;

      .sheets_box_title_box {
        margin: 0 auto;
        width: 1156px;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 20px;
          font-family: Arial;
          font-weight: bold;
          color: #004a9c;
          line-height: 80px;
        }
        img {
          height: 35px;
          width: 35px;
          padding-top: 22.5px;
        }
      }
    }
    .sheets_box_main {
      width: 1156px;
      margin: 0 auto;
      height: 0;
      overflow: hidden;

      .sheets_box_main_title {
        padding: 32px 0 25px;
        font-size: 16px;
        font-family: Arial;
        font-weight: bold;
        color: #5a5b5e;
      }
      .sheets_box_main_list {
        display: flex;
        justify-content: space-between;
        height: 45px;
        cursor: pointer;
        > div {
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #5a5b5e;
        }
        > div:nth-of-type(1) {
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            width: 21px;
            height: 20px;
            padding: 0 60px 0 42px;
          }
        }

        > div:nth-of-type(2) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 37px;
          img {
            width: 20px;
            height: 23px;
          }
          span {
            padding-left: 7px;
            padding-right: 100px;
          }
        }
      }
      .sheets_box_main_list:nth-last-of-type(1) {
        margin-bottom: 20px;
      }
      .sheets_box_main_list:nth-of-type(2n) {
        background: #f1f2f7;
      }
    }
    .show {
      height: auto;
    }
  }
  .sheets_box:last-child {
    border: none;
  }
}
.models {
  .models_title {
    text-align: center;

    padding: 87px 0 50px 0;
    font-size: 36px;
    font-family: Arial;
    font-weight: normal;
    color: #004a9c;
  }
  .models_box {
    border-bottom: 1px dashed #ccc;
    .models_box_title {
      height: 80px;
      background: #f1f2f7;
      .models_box_title_box {
        margin: 0 auto;
        width: 1156px;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 20px;
          font-family: Arial;
          font-weight: bold;
          color: #004a9c;
          line-height: 80px;
        }
        img {
          height: 35px;
          width: 35px;
          padding-top: 22.5px;
        }
      }
    }

    .models_box_main {
      width: 1156px;
      margin: 0 auto;
      height: 0;
      overflow: hidden;
      .models_box_main_list {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        height: 165px;
        img {
          width: 130px;
          height: 130px;
          padding: 18px;
        }
        > div {
          margin: 18px 20px;
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          div:nth-of-type(1) {
            color: #5a5b5e;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
          }
          div:nth-of-type(2) {
            color: #0d53a1;
          }
        }
      }

      .models_box_main_list:nth-of-type(2n-1) {
        background: #f1f2f7;
      }
    }

    .show {
      height: auto;
      padding: 63px 0;
    }
  }
  .models_box:last-child {
    border: none;
  }
}
.popUp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 100vh;
  width: 100vw;
  background: rgba(140, 140, 140, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 102;
  .popUp_main {
    width: 1050px;
    height: 644px;
    background: #ffffff;
    border-radius: 10px;
    font-family: Arial;
    font-weight: normal;
    position: relative;
    .popUp_main_title {
      text-align: center;
      font-size: 36px;

      color: #004a9c;
      padding: 88px 0 76px 0;
    }
    .popUp_main_form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 719px;

      margin: 0 auto;
      > div {
        width: 224px;
        margin-bottom: 28px;

        div {
          font-size: 16px;
          color: #172f66;
          margin-bottom: 15px;
        }
        input {
          width: 214px;
          height: 62px;
          background: #ffffff;
          border: 1px solid #004a9c;
          font-size: 16px;
          padding: 0 10px;
        }
      }
    }
    .popUp_main_submit {
      margin: 58px auto 0;
      width: 222px;
      height: 62px;
      background: #004a9c;
      text-align: center;
      font-size: 16px;
      font-family: Arial;
      font-weight: bold;
      color: #ffffff;
      line-height: 62px;
      cursor: pointer;
    }
    img {
      height: 28px;
      width: 28px;
      position: absolute;
      right: -7px;
      top: -7px;
    }
  }
}
.popUp2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 100vh;
  width: 100vw;
  background: rgba(140, 140, 140, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 102;

  > div {
    display: flex;
    justify-content: space-between;
    min-height: 390px;
    width: 1156px;
    background: #ffffff;
    img {
      width: 390px;
      height: 390px;
      padding: 18px;
    }
    > div {
      margin: 18px 20px;
      font-size: 24px;
      line-height: 32px;
      font-family: Arial;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div:nth-of-type(1) {
        color: #5a5b5e;
        margin-bottom: 20px;
      }
      div:nth-of-type(2) {
        color: #0d53a1;
      }
    }
  }
}
</style>


<style scoped>
div /deep/ .el-input__inner {
  height: 64px;
  border: 1px solid #004a9c;
  width: 232px;
  border-radius: 0;
}
div /deep/ .el-input__suffix-inner {
  display: none;
}
</style>